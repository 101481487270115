exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instruktioner-for-kursdeltagare-js": () => import("./../../../src/pages/instruktioner-for-kursdeltagare.js" /* webpackChunkName: "component---src-pages-instruktioner-for-kursdeltagare-js" */),
  "component---src-pages-nya-jagarexamen-for-kursledare-js": () => import("./../../../src/pages/nya-jagarexamen-for-kursledare.js" /* webpackChunkName: "component---src-pages-nya-jagarexamen-for-kursledare-js" */),
  "component---src-pages-om-nya-jagarexamen-js": () => import("./../../../src/pages/om-nya-jagarexamen.js" /* webpackChunkName: "component---src-pages-om-nya-jagarexamen-js" */),
  "component---src-pages-privacy-jaktkalendern-js": () => import("./../../../src/pages/privacy-jaktkalendern.js" /* webpackChunkName: "component---src-pages-privacy-jaktkalendern-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-ta-jagarexamen-js": () => import("./../../../src/pages/ta-jagarexamen.js" /* webpackChunkName: "component---src-pages-ta-jagarexamen-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/article.js" /* webpackChunkName: "component---src-templates-article-article-js" */)
}

